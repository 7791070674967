@tailwind base;
@tailwind components;
@tailwind utilities;



::-webkit-scrollbar {
  display: none;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: none;
}

::-webkit-scrollbar-thumb {
  background: #565656;
  border-radius: 100vh;
  border: 3px solid #cecece0f;
}

::-webkit-scrollbar-thumb:hover {
  background: #6f6f6f;
}

html,
body {
  font-family: "Montserrat", sans-serif;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

html * {
  transition: background-color 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)

}


@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 75px;
    height: 75px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }  

  .skel {
    width: 100%;
    height: 350px;
  }